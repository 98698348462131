@use '../abstracts/mixins' as *;

.quote {
  font-weight: 700;

  figure {
    display: flex;
    width: 80%;
    flex-direction: column;
    margin-inline-start: auto;
    margin-inline-end: auto;

    @include mq(medium) {
      width: 50%;
    }
  }

  blockquote {
    font-size: var(--fs-500);
    // use standard quotes
    //quotes: '„' '”';

    // styled quotes
    quotes: '' ''; //empty quotes, use :after quotes
    position: relative;
    q:after {
      content: '”';
      position: absolute;
      line-height: 0;
      font-size: 10rem;
      bottom: -60px;
      right: 7px;

      @include mq(medium) {
        font-size: 13rem;
        bottom: -53px;
        right: -83px;
      }
    }
  }
  figcaption {
    margin-block-start: 0.625rem;
    font-size: 1.5rem;
    color: var(--clr-neutral-6, dimgrey);
  }
}
