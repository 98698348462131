@use 'sass:list';
@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as f;

.parallel-info {
  &__wrapper {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    gap: var(--spacing-normal);

    .color {
      &--primary {
        --info-background-color: var(--clr-primary);
      }

      &--secondary {
        --info-background-color: var(--clr-secondary);
      }
    }
  }

  &__content {
    background-color: var(--info-background-color);
    color: var(--clr-white);
    padding-inline-start: var(--spacing-normal);
    padding-inline-end: var(--spacing-medium);
    padding-block: var(--spacing-normal);

    h3 {
      color: var(--clr-white);
    }

    h3,
    p {
      margin-block-end: var(--spacing-normal);
    }
  }

  &__button {
    display: inline-block;
    font-size: var(--fs-300);
    font-weight: 700;
    background-color: var(--clr-white);
    color: var(--info-background-color);
    padding-inline: var(--spacing-normal);
    padding-block: var(--spacing-small);
    border-radius: 15px;
    text-decoration: none;
    text-align: center;
    border-color: var(--clr-white);

    &:hover {
      color: var(--clr-white);
      background-color: var(--info-background-color);
    }
  }

  &__annotation {
    font-size: var(--fs-350);
    color: var(--clr-secondary);
    font-weight: 700;
    margin-block-start: var(--spacing-normal);
  }
}

@include mq(small) {
  .parallel-info {
    &__wrapper {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: unset;
    }

    &__first {
      border-radius: 3px 0 0 3px;
    }

    &__second {
      border-radius: 0 3px 3px 0;
    }
  }
}
