.events-listing {
  &__image {
    float: left;
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-inline-end: 1rem;
    margin-block-end: 1rem;
  }

  &__text {
    &::after {
      display: table;
      content: '';
      clear: both;
    }
  }

  &__related-items {
    display: grid;
    gap: var(--spacing-small);
  }

  &__filters {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-block: var(--spacing-medium);

    select {
      padding: 0.75rem;
      border: 2px solid var(--clr-secondary);
      background-color: var(--clr-white);
      border-radius: 5px;
      color: var(--clr-secondary);
    }
  }
}
