@use 'sass:list';
@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as f;

.programmangebot {
  --grid-spacing: 0;
  --inner-padding: 0;
  color: var(--clr-secondary);

  &__title {
    margin-top: var(--spacing-medium);
    text-align: left;
    text-transform: uppercase;
  }

  &__subline {
    font-size: var(--fs-700);
    font-weight: 400;
    color: var(--clr-secondary);
    text-transform: uppercase;
  }

  &__booking-contact {
    a {
      color: var(--clr-secondary);
    }
  }

  .label,
  h2 {
    width: 100%;
    background-color: var(--clr-primary);
    color: var(--clr-white);
    padding-inline: 1rem;
    padding-block: 0.3rem;
    font-weight: 700;
    text-transform: uppercase;
    font-size: var(--fs-600);
    margin-block-end: var(--spacing-small);
    margin-block-start: var(--spacing-medium);
    break-before: auto;
    break-after: avoid;
  }
  .content {
    padding-inline: 1em;
  }

  button.print {
    border: none;
    background-color: unset;
    color: var(--clr-secondary);
    text-decoration: underline;
    cursor: pointer;
  }

  &__booking-link {
    &--default,
    &--plus {
      margin-inline: auto;
      margin-block-end: var(--spacing-normal);
      width: 100%;

      &:first-of-type {
        margin-block-start: var(--spacing-normal);
      }

      &:hover {
        text-decoration: none;
        background-color: var(--clr-white);
        color: var(--clr-secondary);
        border: 2px solid var(--clr-secondary);
      }
    }
  }

  button.dialog__trigger {
    margin-inline: auto;
    margin-block-end: var(--spacing-normal);
    border: 2px solid var(--clr-primary);

    &:hover {
      background-color: var(--clr-white);
      color: var(--clr-primary);
    }

    &:visited,
    &:active {
      color: var(--clr-secondary);
    }
  }

  a.button,
  button.dialog__trigger {
    &:focus {
      color: var(--clr-white);
      outline: medium solid var(--clr-secondary);
    }
  }
}

@include mq(small) {
  .programmangebot {
    .programmangebot__booking-link {
      &--default,
      &--plus {
        max-width: 20em;
      }
    }
  }
}

@include mq(medium) {
  .programmangebot {
    .label,
    h2 {
      background-color: var(--clr-white);
      color: var(--clr-secondary);
      padding-inline: 0;
      border-bottom: 2px solid var(--clr-secondary);
    }

    .content {
      padding-inline: 0;
    }
  }
}

@page {
  size: a4 portrait;
}

@page :blank {
  @top-center {
    content: 'Technisch bedingte leere Seite';
  }
}

@media print {
  picture,
  img,
  footer,
  header,
  button,
  a.programmangebot__booking-link--default,
  a.programmangebot__booking-link--plus {
    display: none !important;
  }

  a,
  .programmangebot .programmangebot__booking-contact a {
    text-decoration: none;
    color: var(--clr-black);

    &:hover {
      text-decoration: none;
    }
  }

  .programmangebot {
    font-size: var(--fs-200);
    color: var(--clr-black);

    &__title,
    &__subline {
      color: var(--clr-black);
    }

    .label,
    h2 {
      font-size: var(--fs-300);
      margin-block-start: var(--spacing-small);
      margin-block-end: 8px;
      border-bottom: 2px solid var(--clr-black);
    }

    h2,
    .label,
    .content {
      padding-inline: 0;
    }
  }
}
