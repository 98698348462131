.select {
  --_border-radius: var(--select-border-radius, 3px);
  display: grid;

  &--fluid {
    justify-content: stretch;
  }

  &__trigger {
    all: unset;
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: var(--_border-radius);
    border: 2px solid var(--clr-secondary, black);
    background: var(--clr-white, white);
    gap: 0.5rem;
    font-size: var(--fs-400);
    padding: 11px 15px;

    & > *:first-child {
      margin-inline-end: auto;
    }

    &:focus-visible {
      outline: 2px solid var(--clr-black, black);
    }

    &--with-indicator {
      appearance: revert;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__viewport {
    padding: 1rem 0;
  }

  &__content {
    background-color: var(--clr-white, white);
    border-radius: var(--_border-radius);
    border: 2px solid var(--clr-secondary, black);
  }

  &__item {
    all: unset;
    display: flex;
    align-items: center;
    user-select: none;
    position: relative;
    padding: 0 2rem;
    cursor: pointer;
    font-size: var(--fs-400);

    &[data-disabled] {
      pointer-events: none;
      cursor: not-allowed;

      & > * {
        color: grey;
      }
    }

    &:focus-visible {
      background: var(--clr-neutral-2, lightgrey);
    }
  }

  &__item-indicator {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    left: 0;
  }

  &__scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-white, white);
    cursor: default;
  }
}
