.accordion {
  padding-block: calc(var(--spacing-medium) / 2);

  & + & {
    border-block-start: 2px solid var(--clr-darkgrey);
  }

  &__trigger {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    margin-inline-end: auto;
    padding: 0;
    cursor: pointer;
    padding-block: calc(var(--spacing-medium) / 2);
  }

  &__icon {
    color: var(--clr-darkgrey, black);
    width: 3rem;
    height: 1.75rem;
    transition: transform 150ms linear;

    path {
      fill: currentColor;
    }

    [data-state='open'] & {
      transform: rotate(180deg);
    }
  }

  &__content {
    padding: 0 2rem;
    padding-inline-start: 3rem;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    .streamfield.image img {
      object-fit: contain;
    }

    h2,
    h3,
    h4 {
      clear: both;
    }

    .richtext-image {
      &.left {
        clear: both;
        float: left;
        margin-inline-end: 1rem;
        margin-block-end: 1rem;
      }

      &.right {
        clear: both;
        float: right;
        margin-inline-end: 1rem;
        margin-block-end: 1rem;
      }
    }
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}
