.message {
  border-radius: var(--border-radius);
  padding: 2.5rem;
  background: linear-gradient(145deg, var(--clr-neutral-2), var(--clr-neutral-5));

  &--error {
    background: var(--clr-error, firebrick);
    color: var(--clr-white, white);
  }

  &--success {
    background: var(--clr-success, LightGreen);
    color: var(--clr-white, white);
  }

  &--warning {
    background: var(--clr-warning, Khaki);
    color: var(--clr-white, white);
  }

  &--information {
    background: var(--clr-information, RoyalBlue);
    color: var(--clr-white, white);
  }

  &__title {
    font-size: var(--fs-600);
    font-weight: 700;
    color: var(--clr-white);
    line-height: 1;
  }

  a {
    color: var(--color);
  }
}
