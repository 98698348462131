.accessibility-settings {
  display: grid;
  gap: 1rem;

  &__link,
  &__button {
    border: 1px solid white;
    background-color: var(--_background-color);
    color: var(--_color);
    padding: 0.625rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    border-radius: var(--border-radius);
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    font-size: var(--fs-400);

    &:focus-visible {
      outline: 2px solid white;
      outline-offset: 0.25rem;
      color: var(--_color);
      text-decoration: underline;
    }
  }

  &__link {
    &:hover {
      text-decoration: underline;
    }
  }
}
