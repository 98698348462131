.ticket-shop-navigation {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.5rem;

  &__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    min-height: 136px;
    border: 1px solid var(--clr-white);
    border-radius: var(--border-radius);
    color: var(--clr-white);
    text-decoration: none;
    font-size: var(--fs-200);
    padding: 0.25rem;
    line-height: 1.2;

    &:hover,
    &:focus-visible {
      background-color: var(--clr-white);
      color: var(--clr-secondary);
      text-decoration: none;

      svg {
        color: var(--clr-secondary);
      }
    }

    strong {
      font-size: var(--fs-600);
    }

    .column {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .column--centered {
      align-items: center;
    }
  }

  &__link--shop {
    grid-column: 1 / -1;
    grid-row: 1;
  }

  &__link--tours,
  &__link--merchandise {
    flex-direction: column;
  }

  &__link--tours {
    grid-column: 1;
    grid-row: 2;
  }

  &__link--merchandise {
    grid-column: 2;
    grid-row: 2;
  }
}
