.navigation-accordion {
  &__trigger {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    padding: 0;
  }

  &__chevron {
    transition: transform 200ms;
  }

  &__trigger[data-state='open'] &__chevron {
    transform: rotate(180deg);
  }
}
