@use '../abstracts/mixins' as *;

.tickets {
  &__link {
    margin-block-start: var(--spacing-normal);
  }
}

.ticket {
  position: relative;
  display: block;
  background-color: var(--clr-primary);
  color: var(--clr-white);
  text-decoration: none;
  isolation: isolate;

  & + & {
    margin-block-start: var(--spacing-small);
  }

  &:active,
  &:focus,
  &:hover {
    color: var(--clr-white);
    text-decoration: none;
  }

  &:focus-visible {
    outline-offset: 4px;
    outline-width: 4px;
    outline-color: var(--clr-primary);
  }

  &__image {
    height: 100%;
    width: 100%;
    max-width: unset;
    object-fit: cover;
  }

  &__content {
    padding: var(--spacing-normal);
  }

  &__title {
    font-size: var(--fs-600);
    font-weight: 700;
  }

  &__times {
    margin-block-end: var(--spacing-small);
  }

  &__description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__caret {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    border: 0.75rem solid transparent;
    border-left-color: white;
    transform: translateX(50%);
  }
}

@include mq(small) {
  .ticket {
    display: grid;
    grid-template-columns: 200px 1fr;
  }
}

@include mq(medium) {
  .ticket {
    grid-template-columns: 400px 1fr;

    &__image {
      max-height: 300px;
    }
  }
}
