@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as *;

.footer {
  margin-block-start: var(--spacing-large);

  &__nav {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
    width: 100%;
    margin-inline: auto;
    color: var(--clr-white);
    background-color: var(--clr-accent);
    gap: 2rem 4rem;
    border: 2px solid var(--clr-white);
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    color: white;
    border-bottom: 2px solid var(--clr-white);
    padding: fluid(16px, 48px);

    &:first-of-type {
      border-top: 2px solid var(--clr-white);
      padding-block-start: var(--spacing-medium);
    }

    &:last-of-type {
      padding-block-end: var(--spacing-medium);
    }

    &-title {
      font-size: var(--fs-600);
      font-weight: 700;
      color: var(--clr-white);
      margin-block-end: var(--spacing-small);
    }
  }

  &__link {
    color: var(--clr-white);
    text-decoration: none;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }

  &__link--with-icon {
    display: flex;
    justify-content: space-between;
    border: 2px solid var(--clr-white);
    padding: 1rem;
    font-weight: 700;
    border-radius: var(--border-radius);
    max-width: 350px;

    margin-block-end: 1rem;

    &:hover,
    &:focus-visible {
      text-decoration: none;
      background-color: var(--clr-white);
      color: var(--clr-accent);
    }
  }

  &__text {
    b:only-child {
      font-size: var(--fs-600);
    }
  }

  &__social-links {
    display: flex;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-block: 0.5rem;
  }
}

@include mq(small) {
  .footer {
    &__nav {
      padding: fluid(16px, 48px);
      border: none;
    }

    &__list {
      border: none;
      padding: 0;

      &:first-of-type {
        border: none;
        padding-top: 0;
      }
    }
  }
}
