@use '../../abstracts/mixins' as *;

.slider-input {
  --slider-spacer: 0.5em;

  &__container {
    display: flex;
    align-items: center;

    &::before,
    &::after {
      content: '';
      height: 3px;
      background-color: var(--clr-black, black);
      width: var(--slider-spacer);
    }
  }

  &__label {
    font-size: clamp(1.125rem, 4vw, 1.5rem);
    color: var(--clr-black, black);
  }

  &__root {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    width: 100%;
    height: 75px;
  }

  &__track {
    height: 3px;
    background-color: var(--clr-black, black);
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
  }

  &__range {
    position: absolute;
    background-color: var(--clr-black, black);
    border-radius: 9999px;
    height: 100%;
  }

  &__thumb {
    all: unset;
    display: block;
    width: 24px;
    height: 24px;
    background-color: var(--clr-white, white);
    border: 4px solid var(--clr-black, black);
    box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 10px;
    border-radius: 100px;
    margin: 0 var(--slider-spacer);
    cursor: pointer;

    &:focus-visible {
      box-shadow: 0 0 0 4px var(--clr-black, black);
    }
  }

  &__text {
    display: flex;
    justify-content: space-between;

    p:not(:first-child):not(:last-child) {
      display: none;
    }

    p:last-child {
      text-align: right;
    }

    p {
      color: var(--clr-black, black);
      font-size: clamp(1.25rem, 4vw, 1.5rem);
    }
  }
}

@include mq(medium) {
  .slider-input {
    --slider-spacer: 2em;

    &__text {
      p:not(:first-child):not(:last-child) {
        display: block;
      }
    }
  }
}
