@use '../abstracts/mixins' as *;

.streamfield {
  position: relative;
  z-index: 0;

  & &:first-child {
    margin-block-start: 0;
  }

  & &:last-child {
    margin-block-end: 0;
  }
}
