.accordions {
  .accordion {
    &__content {
      .streamfield:not(:last-child) {
        margin-block-end: var(--spacing-medium);
      }

      .image {
        img {
          height: fit-content;
        }
      }

      .buttons {
        .button {
          &--outline {
            background-color: transparent;
          }
        }
      }
    }
  }
}
