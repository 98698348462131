@use 'sass:list';
@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as f;

.links {
  overflow: hidden;

  &__link-container {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    gap: var(--spacing-small);
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;

    @include mq(big) {
      overflow-x: hidden;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .tile {
    scroll-snap-align: center;
  }

  &.icons .tile {
    aspect-ratio: 1 / 1;
    background-color: var(--clr-primary);
    color: var(--clr-white);
    padding: 1rem;
    border-radius: var(--border-radius);
    display: grid;
    align-content: center;
    justify-content: center;
    justify-items: center;
    text-decoration: none;
    max-width: 250px;
    width: 100%;
    margin-inline: auto;

    &__title {
      margin-block-start: var(--spacing-normal);
      text-align: center;
      font-weight: 700;
      font-size: var(--fs-400);
    }
  }

  &.images a {
    text-decoration: none;
  }

  &.images .tile {
    flex: 1;

    &__title {
      color: var(--clr-secondary);
      font-weight: 700;
      margin-block-start: var(--spacing-small);
    }

    &__image {
      aspect-ratio: 1 / 1;
      width: 100%;
      min-width: 264px;
      object-fit: cover;
    }
  }

  .tile {
    &:hover {
      text-decoration: underline;
    }
  }
}
