@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as *;

.navigation {
  position: fixed;
  background-color: var(--clr-secondary);
  overscroll-behavior: contain;
  overflow: auto;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100dvw;
  max-width: 30em;
  color: var(--clr-white);

  @include mq(small) {
    height: fit-content;
  }

  h2 {
    color: var(--clr-white);
  }

  section {
    padding: 1.5rem 1rem;
    border: 2px solid var(--clr-white);

    &:first-of-type {
      padding-block-start: 4.5rem;
    }
  }

  section + section {
    border-top: 0;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 700;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__link {
    color: var(--clr-white);
    text-decoration: none;
  }
}

#navigation-menu {
  transform: translateX(100vw);
  transition: transform 200ms ease-in-out;
  // display: none;
}

.hamburger[aria-expanded] + #navigation-menu {
  transform: translateX(0);
}
