// * {
//   // font-variation-settings: 'wght' var(--font-weight, 375), 'wdth' var(--font-width, 80);
//   font-variation-settings: 'wght' var(--font-weight, 400);
// }

body {
  font-size: var(--fs-400);
  color: var(--clr-black);
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  font-stretch: 100%;
  line-height: 1.1;
  color: var(--clr-secondary);
  text-transform: uppercase;
}

// This is setting the font-sizes
// based on the ones in the abstract
// folder, go there to make updates

h1,
.h1 {
  font-size: var(--fs-800);
}

h2,
.h2 {
  font-size: var(--fs-700);
  margin-block-end: var(--spacing-normal);
}

h3,
.h3 {
  font-size: var(--fs-600);
  margin-block-end: var(--spacing-small);
}

h4,
.h4 {
  font-size: var(--fs-500);
}

small,
.text-small {
  font-size: var(--fs-300);
}

strong {
  font-weight: 700;
}

a {
  color: var(--clr-black);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

main {
  p:where(:not(:last-child)) {
    margin-block-end: 1lh;
  }
}

// p:not([class]),
// ul:not([class]),
// li:not([class]) {
//   max-width: 60ch;
// }
