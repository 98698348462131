.dialog {
  &__overlay {
    background-color: rgba(0 0 0 / 0.3);
    position: fixed;
    inset: 0;
    z-index: 1;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &__trigger {
    --btn-background-color: var(--clr-primary);
    --btn-border-color: var(--clr-white);
  }

  &__trigger:focus,
  &__trigger:focus-visible {
    outline: 2px solid var(--clr-white);
    outline-offset: 3px;
  }

  &__content {
    position: relative;
    background-color: white;
    border-radius: 6px;
    box-shadow:
      hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    max-width: 900px;
    max-height: 85vh;
    padding: 25px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    overflow-y: auto;
    overscroll-behavior: contain;
    z-index: 1;

    &:focus {
      outline: none;
    }
  }

  &__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background-color: transparent;
    border: none;
    display: inline-grid;
    place-items: center;
    padding: 0.5rem;

    &:hover {
      cursor: pointer;
      background-color: var(--clr-lightgrey);
    }
  }

  &__title {
  }

  &__description {
    margin-block-end: var(--spacing-medium);
  }
}
