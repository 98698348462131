.search-results {
  &__title {
    margin-block-start: 4rem;
    margin-block-end: 0rem;
  }

  &__list {
    padding: 0;
  }

  &__item {
    & + & {
      border-top: 1px solid var(--clr-lightgrey);
    }
  }

  &__link {
    display: inline-block;
    margin: 0.5em 0;
    text-decoration: none;
  }

  &__item-title {
    font-size: var(--fs-600);
    color: var(--clr-secondary);
  }

  &__item-url {
    font-size: var(--fs-300);
  }
}
