.slider {
  .splide {
    .splide__arrow:focus-visible {
      outline: 2px solid var(--clr-focus-1);
      outline-offset: 3px;
    }
  }

  &__image {
    height: auto;
    width: 100%;
    // max-width: 200px;
  }
}
