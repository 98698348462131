@use '../abstracts/mixins' as *;
.featuretiles {
  .featuretile {
    margin-block-end: var(--spacer-md);
    &__bgwrapper {
      margin-block-end: var(--spacer-md);

      > div {
        margin-block-end: var(--spacer-sm);
      }
    }
  }
  &__wrapper {
    @include mq(medium) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 20rem));
      justify-content: center;
      grid-gap: 2rem;
    }
  }
}
