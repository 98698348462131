@use '../abstracts/_mixins.scss' as *;

.banner-video {
  height: 550px;
  overflow: hidden;
  position: relative;
  max-width: 1920px;
  margin-inline: auto;

  video {
    position: absolute;
    top: 0;
    bottom: 0;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    width: 100%;
    height: 100%;

    source {
      display: none;
    }
  }

  &__links {
    position: absolute;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    grid-column: full-width !important;
    bottom: 1rem;
    left: 1rem;
  }

  &__link {
    border-radius: var(--border-radius);
    border: 2px solid var(--clr-white);
    color: var(--clr-white);
    background-color: rgba(255 255 255 / 0.2);
    font-weight: 700;
    padding: 0.5rem 1rem;
    text-decoration: none;
    display: inline-flex;
    gap: 2rem;
    align-items: center;
    backdrop-filter: blur(10px);

    &:hover {
      text-decoration: underline;
    }
  }
}

@include mq(small) {
  .banner-video {
    height: 720px;

    &__links {
      bottom: 2rem;
      left: 3rem;
    }
  }
}
