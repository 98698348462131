@use '../abstracts/mixins' as *;
@use '../utilities/container';

.hero {
  &__title {
    text-align: center;
  }

  &__subtitle {
    text-align: center;
  }

  &__text {
    font-size: var(--fs-500);
    text-align: center;

    margin-inline: auto;
  }
}

@include mq(medium) {
  .hero {
    &__text {
      max-width: 60ch;
    }
  }
}
