@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as *;

.navigation-tabs {
  color: white;
  background-color: var(--clr-secondary);
  position: relative;

  &__list {
    padding-inline-end: calc(
      fluid(16px, 48px) - 1rem
    ); // same padding as hamburger button minus container padding to ensure alignment
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  &__trigger {
    --_bg-color: var(--bg-color, transparent);
    --_color: var(--color, white);

    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: var(--_bg-color);
    color: var(--_color);
    border-radius: 5px;
    width: 55px;
    height: 42px;
    cursor: pointer;
    flex-shrink: 0;
    padding-inline: 0;

    svg {
      flex-shrink: 0;
    }

    &[data-state='active'] {
      --color: var(--clr-secondary);
      --bg-color: white;

      svg {
        color: var(--clr-secondary);
      }
    }
  }

  &__content {
    padding-block-start: 1.25rem;
  }

  &__content[data-state='inactive'] {
    display: none;
  }
}
