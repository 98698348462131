@use './label';

.fieldset {
  border: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 1rem;
  margin-block-end: var(--spacing-small);

  &__legend {
    @extend .label !optional;
    font-size: var(--fs-600);
  }

  &--as-label &__legend {
    color: var(--clr-black, black);
    font-size: var(--fs-300);
    font-weight: 700;
    display: inline-block;
    padding: 0 1rem;
    margin-block-end: 0.5rem;
    text-align: left;
  }
}
