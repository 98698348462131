.search-form {
  --_color: var(--search-form-color, var(--clr-white));
  --_background-color: var(--search-form-bg-color, var(--clr-secondary));
  --_border-color: var(--search-form-border-color, var(--_color));

  &--light {
    --search-form-color: var(--clr-secondary);
    --search-form-bg-color: var(--clr-white);
  }

  &__form {
    display: flex;
    align-items: stretch;
    margin: 1.5rem 0 2rem 0;
    overflow: hidden;
    border-radius: 5px;
    border: 2px solid var(--_border-color);

    & > *:first-child {
      flex: 1;
    }
  }

  &__input {
    border: none;
    background-color: transparent;
    color: var(--_color);
    padding: 0.75rem 1.5rem;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid var(--_border-color);
      outline-offset: -4px;
    }

    &::placeholder {
      font-size: var(--fs-500);
      color: inherit;
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    background-color: var(--_color);
    border: none;
    cursor: pointer;
    padding-inline: 1rem;

    &:focus-visible {
      outline: 2px solid var(--_border-color);
      outline-offset: -4px;
    }

    svg {
      color: var(--_background-color);
    }
  }

  &__faqs {
    border-top: 2px solid var(--_border-color);
    padding-block-start: 1rem;
  }

  &__faq-link {
    display: block;
    text-align: center;
    color: var(--clr-white);
    text-decoration: none;
    outline: 2px solid var(--_border-color);
    border-radius: var(--border-radius);
    padding-block: 1rem;
  }

  &__quicksearch-keywords {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-block-end: 1rem;
  }

  &__quicksearch {
    display: inline-block;
    background-color: var(--_background-color);
    color: var(--_color);
    padding: 0.25rem 0.75rem;
    border-radius: 1000px;
    font-size: var(--fs-300);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
