@use '../abstracts/mixins' as *;

.banner-image {
  min-height: 250px;
  height: auto;
  width: 100%;
  object-fit: cover;
}

@include mq(medium) {
  .banner-image {
    min-height: 500px;
    width: 100%;
    max-width: 1920px;
    margin-inline: auto;
  }
}
