.loading-skeleton {
  display: block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  border-radius: 5px;
  margin-block-end: 1em;
  font-size: inherit;

  &--no-margin {
    margin-block-end: 0;
  }

  &--no-border {
    border-radius: 0;
  }

  &::after {
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 5s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
