.richtext {
  &::after {
    content: '';
    display: table;
    clear: both;
  }

  a {
    word-break: break-word;
  }

  .richtext-image {
    &.left {
      float: left;
      margin-inline-end: 1rem;
      margin-block-end: 1rem;
    }

    &.right {
      float: right;
      margin-inline-end: 1rem;
      margin-block-end: 1rem;
    }
  }
}
