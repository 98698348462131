@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as *;

.header {
  --_header-height: 5rem;
  --_bg-clr: var(--bg-color, var(--clr-white));

  max-width: 1920px;
  margin-inline: auto;
  width: 100%;

  position: sticky;
  top: 0;
  background-color: var(--_bg-clr);
  min-height: var(--_header-height);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  &__logo {
    height: 2.6rem;
    position: relative;
    top: 0.3125rem;
  }

  &__link {
    display: inline-block;
    padding: 1.5rem;
  }

  &:has(.nav--open) {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

@include mq(small) {
  .header {
    padding-inline: fluid(16px, 48px);
  }
}
