@use 'sass:list';
@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as f;

.divider {
  height: 4px;
  background: var(--clr-primary);

  @include mq(big) {
    height: 6px;
  }
}
