@use '../abstracts/mixins' as *;
@use '../utilities/container';

.container-field {
  --_container-bg-color: var(--container-bg-color, transparent);

  padding-block: 5rem;
  background: var(--_container-bg-color);

  & > .streamfield {
    border: 0;
    background: transparent;
  }

  &--lightgrey {
    --container-bg-color: var(--clr-lightgrey);
  }
}
