@use '../components/tickets';
@use '../abstracts/mixins' as *;

.related-item {
  @extend .ticket !optional;

  &__title {
    @extend .ticket__title !optional;
    color: var(--clr-white);
  }

  &__subtitle {
    margin-block-end: var(--spacing-small);
  }

  &__image {
    @extend .ticket__image !optional;
    height: auto;
  }

  &__content {
    @extend .ticket__content !optional;
  }

  &__registration {
    margin-block-start: var(--spacing-small);
  }

  &__link,
  &__link:active,
  &__link:focus {
    --btn-background-color: transparent;
    --btn-color: var(--clr-white);
    --btn-border-color: var(--btn-color);
  }

  &__link:focus-visible {
    outline: 2px solid currentColor;
  }

  &__attachment {
    display: inline-block;
    margin-block: var(--spacing-small);
    color: var(--clr-white);
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    gap: 0 1rem;
  }

  &__registration {
    width: 100%;
  }
}

@include mq(small) {
  .related-item {
    &__image {
      height: 100%;
      max-height: none;
    }

    &__registration {
      width: fit-content;
    }
  }
}
