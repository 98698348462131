.checkbox {
  display: flex;
  align-items: center;

  &--baseline {
    align-items: flex-start;
  }

  &__root {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    background-color: white;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 3px solid var(--clr-black, black);
    cursor: pointer;
    flex-shrink: 0;

    &:hover {
      //background-color: rgba(c.$purple, 0.2);
    }

    &:focus-visible {
      box-shadow: 0 0 0 6px var(--clr-black, black);
    }
  }

  &__indicator {
    color: var(--clr-black, black);
    height: 24px;
  }

  &__label {
    color: var(--clr-black, black);
    padding: 0 0 0 0.5em;
    font-size: clamp(1.125rem, 4vw, 1.5rem);
    line-height: 1.4;
    cursor: pointer;
    user-select: none;

    &--small {
      font-size: clamp(1rem, 4vw, 1.5rem);
    }

    a {
      color: inherit;
    }
  }
}
