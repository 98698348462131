@use '../abstracts/mixins' as *;

.button {
  --_btn-radius: 16px;
  --_btn-bg: var(--btn-background-color, var(--clr-secondary));
  --_btn-clr: var(--btn-color, var(--clr-white));
  --_btn-border-clr: var(--btn-border-color, var(--_btn-bg));
  --_btn-padding-block: var(--btn-padding-block, calc(var(--spacing-normal) / 2));
  --_btn-padding-inline: var(--btn-padding-inline, var(--spacing-normal));

  display: inline-block;
  width: 100%;
  color: var(--_btn-clr);
  background-color: var(--_btn-bg);
  border: 2px solid;
  border-color: var(--_btn-border-clr);
  border-radius: var(--_btn-radius);
  padding-inline: var(--_btn-padding-inline);
  padding-block: var(--_btn-padding-block);
  cursor: pointer;
  font-weight: 700;
  font-size: var(--fs-300);
  text-decoration: none;
  transition:
    box-shadow ease 0.2s,
    color ease 0.2s,
    background-color ease 0.3s;
  text-align: center;

  &:focus {
    --btn-color: var(--_btn-clr);
  }

  &:focus-visible {
    outline: 2px solid var(--clr-btn-bg);
    outline-offset: 3px;
    color: var(--_btn-clr);
  }

  &:hover {
    text-decoration: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    color: var(--_btn-clr);
  }

  &.disabled,
  &:disabled {
    --btn-background-color: grey;

    &:hover {
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &--text {
    --btn-background-color: var(--clr-primary);
  }

  &--outline {
    --btn-background-color: var(--clr-white);
    --btn-color: var(--clr-secondary);
    --btn-border-color: var(--clr-secondary);

    &.disabled,
    &:disabled {
      --btn-background-color: grey;
    }
  }

  &--outline-white {
    --btn-background-color: transparent;
    --btn-color: var(--clr-white);
    --btn-border-color: var(--clr-white);

    &.disabled,
    &:disabled {
      --btn-background-color: grey;
    }
  }
}

@include mq(small) {
  .button {
    width: fit-content;
  }
}
