.visitor-information {
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 0.25rem;
    margin-block-end: 1rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    color: var(--clr-white);
    background-color: var(--clr-primary);
    padding: var(--spacing-medium);
    min-height: 300px;
  }

  &__text {
    text-align: center;
    font-size: var(--fs-500);

    b {
      font-size: var(--fs-600);
    }
  }

  &__times {
    font-weight: 700;
    font-size: var(--fs-600);
    text-align: center;
    margin-block-end: 0;

    p {
      font-weight: 700;
      margin-block-end: 0;
    }
  }
}
