.image {
  padding: 0;
  margin: var(--spacer-lg) auto 0;

  &--full-width {
    width: auto;
  }

  &--full-width &__element {
    border-radius: 0;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  &__element {
    border-radius: var(--border-radius);
  }

  img {
    object-fit: contain;
    height: auto;
  }
}
