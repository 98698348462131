@use '../../utilities/container';

.form {
  display: grid;
  gap: 1rem;

  &__row {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  & fieldset {
    display: grid;
    gap: 1rem;
  }

  &__submit {
    width: max-content;
    margin-inline: auto;
  }
}
