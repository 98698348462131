.textarea {
  textarea {
    width: 100%;
    border: 2px solid var(--clr-secondary);
    border-radius: 5px;
    resize: vertical;
    min-height: 200px;
    padding: 1rem;
    font-size: var(--fs-400);
    color: var(--clr-secondary, black);

    &:focus {
      outline: 1px solid var(--clr-secondary, black);
    }
  }
}
