@use '../abstracts/mixins' as *;

.info-block {
  border: 4px solid var(--clr-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--clr-primary);
  padding-block: var(--spacing-medium);
  padding-inline: var(--spacing-small);

  &__icon-wrapper {
    background-color: var(--clr-primary);
    border-radius: 50%;
    aspect-ratio: 1/1;
    width: 100px;
    height: auto;
    display: grid;
    place-content: center;
    margin-block-end: var(--spacing-medium);
  }

  &__content {
    h2,
    h3,
    h4 {
      color: var(--clr-primary);
      font-size: var(--fs-500);
    }

    a {
      color: var(--clr-primary);
    }
  }
}

@include mq(small) {
  .info-block {
    flex-direction: row;
    padding-inline: var(--spacing-medium);

    &__icon-wrapper {
      margin-block-end: 0;
      margin-inline-end: var(--spacing-medium);
      flex-shrink: 0;
    }
  }
}
