@use 'sass:list';
@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as f;

.cta {
  --_color: var(--cta-color, var(--clr-white));
  --_background-color: var(--cta-background-color, var(--clr-primary));

  display: grid;
  background-color: var(--_background-color);
  color: var(--_color);

  &__image,
  &__image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__content {
    padding-block-start: var(--spacing-normal);
    padding-block-end: var(--spacing-large);
    padding-inline: f.fluid(16px, 48px);
  }

  &__text {
    margin-block-end: var(--spacing-normal);

    h2,
    h3,
    h4,
    a {
      color: var(--clr-white);
    }
  }

  &--secondary {
    --cta-background-color: var(--clr-secondary);
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-small);

    .button.button--outline {
      background-color: var(--cta-background-color);
      color: var(--clr-white);
      border-color: var(--clr-white);

      &:hover {
        background-color: var(--clr-white);
        color: var(--_background-color);
      }
    }
  }
}

@include mq(small) {
  .cta {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    &--inverse &__image {
      grid-column: 2;
      grid-row: 1;
    }

    &--inverse &__content {
      grid-column: 1;
      grid-row: 1;
    }
  }
}

@include mq(big) {
  .cta {
    align-items: center;

    &__content {
      padding-block: var(--spacing-large);
    }
  }
}
