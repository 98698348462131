.input {
  display: grid;

  &__label {
    color: var(--clr-secondary, black);
    font-size: var(--fs-300);
    user-select: none;
  }

  &__element {
    border: none;
    border-radius: 3px;
    font-size: var(--fs-300);
    padding: 11px 15px;
    border: 2px solid var(--clr-secondary, black);
    color: var(--clr-secondary, black);
    accent-color: var(--clr-secondary);

    &:focus {
      outline: 1px solid var(--clr-secondary, black);
    }

    &::-webkit-calendar-picker-indicator {
      color: var(--clr-secondary, black);
    }
  }
}
