.locale-navigation {
  &__title {
    color: inherit;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__list {
    display: grid;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__link {
    --_background-color: var(--background-color, transparent);
    --_color: var(--color, white);

    border: 1px solid white;
    background-color: var(--_background-color);
    color: var(--_color);
    padding: 0.625rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    border-radius: var(--border-radius);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: var(--_color);
    }

    &:focus-visible {
      outline: 2px solid white;
      outline-offset: 0.25rem;
      color: var(--_color);
      text-decoration: underline;
    }
  }

  html[lang='de'] &__link[data-language='de'],
  html[lang='en'] &__link[data-language='en'],
  html[lang='nl'] &__link[data-language='nl'] {
    --background-color: white;
    --color: var(--clr-secondary);
  }
}
