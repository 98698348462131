.main-navigation {
  &__list {
    list-style: none;
    padding: 0;
    margin-block-start: 0;

    &-item {
      font-size: var(--fs-600);
      font-weight: 700;
      margin-block-start: 0.75em;

      .main-navigation__list-item {
        font-size: var(--fs-400);
        font-weight: 400;
        margin-block-start: 0;
      }
    }
  }

  &__list &__list {
    padding-inline-start: var(--spacing-small);
  }

  &__link {
    color: var(--clr-white);
    text-decoration: none;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }
}
