@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as *;

.sponsors {
  display: flex;
  gap: var(--spacing-medium);
  padding: var(--spacing-large);
  flex-wrap: wrap;

  .sponsor {
    &__label {
      font-weight: 700;
      margin-block-end: var(--spacing-small);
      display: block;
    }

    &__logo {
      height: 3rem;
      width: auto;
    }

    &__wrapper {
      display: grid;
      align-content: end;
    }

    &__label-wrapper {
      display: grid;
      align-content: end;
    }
  }
}
