.hamburger {
  --width: 2.25rem;
  --clr: var(--clr-primary, black);
  --gap: 0.375rem;
  --thickness: 0.25rem;

  gap: var(--gap);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: transform ease-in-out 200ms;
  padding: 1rem 0.5rem;

  span {
    background: var(--clr);
    height: var(--thickness);
    width: 100%;
  }

  &::before,
  &::after {
    content: '';
    background: var(--clr);
    height: var(--thickness);
    width: var(--width);
    position: relative;
    transition: all ease-in-out 200ms;
  }

  &::before {
    left: 0;
  }

  &::after {
    top: 0;
    left: 0;
  }

  &[aria-expanded] {
    --clr: white;
    transform: translateX(-0.25rem);

    &::before {
      top: 0.625rem;
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
      top: -0.625rem;
    }

    span {
      opacity: 0;
    }
  }
}
