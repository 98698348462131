@use 'sass:list';
@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as f;

.sub-navigation {
  padding: 1.5rem 1rem;
  border: 2px solid var(--clr-white);
  border-top: none;

  @include mq(small) {
    border: none;
    padding: 0;
  }

  &__container {
    border: none;
    width: 100%;
  }

  &__title {
    margin-block-start: 0;
    color: var(--clr-white);
    font-size: var(--fs-400);
    font-weight: 700;
    margin-block-end: 0;
    text-transform: none;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__link {
    color: var(--clr-white);
    text-decoration: none;
  }
}

@include mq(small) {
  .sub-navigation {
    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-inline: var(--spacing-medium);
      padding-block: var(--spacing-normal);
      border: 2px solid var(--clr-white);
      border-top: none;
    }
  }
}
